import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { BlogComponent } from './pages/blog/blog.component';
import { AboutComponent } from './pages/about/about.component';
import { ProgrammingComponent } from './pages/blog/programming/programming.component';
import { EntrepreneurshipComponent } from './pages/blog/entrepreneurship/entrepreneurship.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'blog', component: BlogComponent, children: [
    {
      path: 'programming', component: ProgrammingComponent
    },
    {
      path: 'entrepreneurship', component: EntrepreneurshipComponent
    }
  ] },
  { path: 'about', component: AboutComponent },
  { path: 'thank-you', component: ThankYouComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
