import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { HeroComponent } from './components/hero/hero.component';
import { ServicesComponent } from './pages/services/services.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogComponent } from './pages/blog/blog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServicesBlockComponent } from './components/services-block/services-block.component';
import { AboutBlockComponent } from './components/about-block/about-block.component';
import { ContactMeBlockComponent } from './components/contact-me-block/contact-me-block.component';
import { DetailedServicesBlockComponent } from './components/detailed-services-block/detailed-services-block.component';
import { ShortAboutBlockComponent } from './components/short-about-block/short-about-block.component';
import { ProgrammingComponent } from './pages/blog/programming/programming.component';
import { EntrepreneurshipComponent } from './pages/blog/entrepreneurship/entrepreneurship.component';
import { ArticleComponent } from './components/blog/article/article.component';
import { ArticleListComponent } from './components/blog/article-list/article-list.component';
import { SearchComponent } from './components/blog/search/search.component';
import { SocialShareComponent } from './components/blog/social-share/social-share.component';
import { ReadNextComponent } from './components/blog/read-next/read-next.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HeroComponent,
    ServicesComponent,
    AboutComponent,
    BlogComponent,
    ServicesBlockComponent,
    AboutBlockComponent,
    ContactMeBlockComponent,
    DetailedServicesBlockComponent,
    ShortAboutBlockComponent,
    ProgrammingComponent,
    EntrepreneurshipComponent,
    ArticleComponent,
    ArticleListComponent,
    SearchComponent,
    SocialShareComponent,
    ReadNextComponent,
    ThankYouComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FontAwesomeModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
