import { Component, OnInit } from '@angular/core';
import { SocialIcon } from 'src/app/classes';
import { faFacebookF, faTwitter, faLinkedinIn, faMediumM, faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  socialIcons: SocialIcon[];
  constructor() {
    this.socialIcons = new Array<SocialIcon>();
    this.setupSocialIcons();
  }

  ngOnInit(): void {
  }

  setupSocialIcons() {
    const fb = new SocialIcon(faFacebookF, 'https://www.facebook.com/ryan.handby');
    const linkedin = new SocialIcon(faLinkedinIn, 'https://www.linkedin.com/in/ryan-handby-22881557/');
    const twitter = new SocialIcon(faTwitter, 'https://twitter.com/hbthegreat_');
    const medium = new SocialIcon(faMediumM, 'https://medium.com/@ryanhandby');
    const insta = new SocialIcon(faInstagram, 'https://www.instagram.com/ryanhandby/')
    this.socialIcons.push(fb, linkedin, twitter, medium, insta);
  }

}
