import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/classes';
import { ServicesService } from 'src/app/services/services.service';

@Component({
  selector: 'app-detailed-services-block',
  templateUrl: './detailed-services-block.component.html',
  styleUrls: ['./detailed-services-block.component.scss']
})
export class DetailedServicesBlockComponent implements OnInit {
  services: Service[];
  constructor(private servicesService: ServicesService) {
    this.constructServices();
  }

  ngOnInit(): void {
  }

  constructServices(){

    this.services = new Array<Service>();

    this.services.push(...this.servicesService.getServices());
  }

}
