import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/classes';
import { ServicesService } from 'src/app/services/services.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-services-block',
  templateUrl: './services-block.component.html',
  styleUrls: ['./services-block.component.scss']
})
export class ServicesBlockComponent implements OnInit {
  services: Service[];
  needRouterLink: boolean;
  constructor(private servicesService: ServicesService, private route: ActivatedRoute) {
    this.route.url.subscribe((url) => {
      if (url.length === 0) {
        this.needRouterLink = true;
        return;
      }
      if (url[0].path === 'services') {
        this.needRouterLink = false;
      } else {
        this.needRouterLink = true;
      }
    });

    this.constructServices();
  }

  ngOnInit(): void {
  }

  constructServices() {

    this.services = new Array<Service>();

    this.services.push(...this.servicesService.getServices());
  }

}
