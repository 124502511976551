import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

export class Service {
    // tslint:disable-next-line: max-line-length
    constructor(fragment: string, icon: IconDefinition, name: string, shortDescription: string, longDescription: string, dotPoints?: string[], longDescriptionContinued?: string) {
        this.fragment = fragment;
        this.icon = icon;
        this.name = name;
        this.shortDescription = shortDescription;
        this.longDescription = longDescription;
        this.dotPoints = dotPoints;
        this.longDescriptionContinued = longDescriptionContinued;
    }
    fragment: string;
    icon: IconDefinition;
    name: string;
    shortDescription: string;
    longDescription: string;
    dotPoints?: string[];
    longDescriptionContinued?: string;
}
