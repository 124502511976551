import { Component, OnInit } from '@angular/core';
import { faFacebookF, faLinkedinIn, faTwitter, faInstagram, faMediumM } from '@fortawesome/free-brands-svg-icons';
import { SocialIcon } from 'src/app/classes';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  socialIcons: SocialIcon[];
  constructor() {
    this.socialIcons = new Array<SocialIcon>();
    this.setupSocialIcons();
  }

  ngOnInit(): void {
  }

  setupSocialIcons() {
    const fb = new SocialIcon(faFacebookF, 'https://www.facebook.com/ryan.handby');
    const linkedin = new SocialIcon(faLinkedinIn, 'https://www.linkedin.com/in/ryan-handby-22881557/');
    const twitter = new SocialIcon(faTwitter, 'https://twitter.com/hbthegreat_');
    const medium = new SocialIcon(faMediumM, 'https://medium.com/@ryanhandby');
    const insta = new SocialIcon(faInstagram, 'https://www.instagram.com/ryanhandby/')
    const pod = new SocialIcon(faPodcast, 'https://anchor.fm/brief-and-deep')
    this.socialIcons.push(fb, linkedin, twitter, medium, insta, pod);
  }
}
