<div class="mt-5">
    <h1 class="text-center mt-5">Thank you for taking the time to connect <span class="highlight">.</span><span class="highlight-two">.</span><span class="highlight-three">.</span></h1>
    <p class="text-center subheading">If you would like to follow me on other social media you can find those here.</p>
    <div class="col-12 text-center">
        <ul class="list-inline">
            <li class="list-inline-item" *ngFor="let socialIcon of socialIcons">
                <a href="{{socialIcon.link}}" target="_blank">
                    <fa-icon [icon]="socialIcon.icon" [styles]="{'color': '#fff'}"></fa-icon>
                </a>
            </li>
        </ul>
    </div>
</div>