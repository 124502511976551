<div class="container">
    <h1 class="text-center">My Story<span class="highlight">.</span></h1>
    <p class="subheading">I have been living and breathing the web, technology and business in all aspects since a young age. During my time in the business and development world I have learnt to appreciate beauty in simplicity and ensuring a constant strive for business value even at the expense of technical goals</p>
    <h2>Early life<span class="highlight">:</span></h2>
    <p>Although I may be a millenial I grew up building digital products and websites about a decade before many of my peers got into the space. I hacked away at custom code on MySpace and Neopets years before I even knew I wanted to become a developer.</p>
    <p>By the age of 12 I had built my first website for a local business and they wanted to pay me! Young Ryan thought "Wow, there must be something to this".</p>
    <p>I continued with a fervent interest in all technological developments in a time before mobile phones, Google Chrome and Facebook and decided to pusue Higher Education in a double degree of Computer Science and Software Engineering</p>
    <h2>Career<span class="highlight">:</span></h2>
    <p>I have been involved in many startups and businesses myself over the years as a founder, core team member and employee. This has let me see a great divide between what makes a profitable and successful engineering team and what leads to failure.</p>
    <p>I began my career working on educational software that allowed students to take exams online while continuing to be limited by time deadlines and real time updates for teachers. During this time I learnt a lot about cloud based servers and the power of web apps beyond standard HTML, CSS and JavaScript.</p>
    <p>Which led me to begin building a live streaming website called Disco Melee that was an early competitor to Twitch.tv. but hybridised with a Teespring style store and Facebook Group-esque community for Esports organisations. Our team built this product to achieve sub 2 second latency worldwide for live stream delivery to handle over 100,000 concurrent viewers. This business ended up being my first exit albeit smaller than most it was an achievement nonetheless.</p>
    <p>After my foray into live streaming I came back home to Australia from the USA and continued work as a freelance developer while thinking about my next entrepreneurial step. Throughout this time I realised that there were a lot of holes in the tooling available to young businesses, freelancers and contractors around the world. It wouldn't be until a few years later that I thought of a solution for this.</p>
    <p>At this point I decided it was time for me to get some more deep experience in a product based development company. So I joined the team at Roller Software that were at the time building ticket booking software widgets for customers that already had their own websites. When I joined this team I was in the first 5 developers to join the team while the company was doing around $200k per year in booking volume through their platform. I spent 3 wonderful years with this team and was a core team member in producing a payment solution for Point of Sale devices at hundreds of venues worldwide which gave me a massive insight into the payments industry and what customers really wanted in a reliable solution. By the time I departed the team was about to take their first $1 billion through the platform which was a massive achievement. If your business takes online bookings you should check them out.</p>
    <p>During my time at Roller I had continued building my own development business on the side and had over one hundred recurring customers. During these years I undertook a lot of learning around the ins and outs of making sure I could create a wonderful outcome for my clients and getting paid for that work.</p>
    <p>Seeing my family, friends and business colleagues struggling with receiving payment for work they had done. I decided to set up a business with my co-founder called James called <a href="https://milestonepay.com">MilestonePay</a> that allows businesses all over the world to take payments for work they are completing in advance to remove that risk of not being paid and that is where I currently am in this journey.</p>
</div>
