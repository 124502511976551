<div class="container">
    <h1 class="text-center">My Story<span class="highlight">.</span></h1>
    <p class="subheading">I have been living and breathing the web, technology and business in all aspects since a young age. During my time in the business and development world I have learnt to appreciate beauty in simplicity and ensuring a constant strive for business value even at the expense of technical goals</p>

    <div class="row mt-5">
        <div class="col-md-4 col-xs-12">
            <h1>Hello, nice to meet you!</h1>
            <p>As you can tell by now my name is Ryan. I am a business builder, knowledge-sharer and software engineer. I have built 5 of my own businesses, been a part of 20+ different startups and have funded my life via freelancing and contracting for over 5 years now.</p>
            <p>I'll keep it brief for now but if you'd like to know more about my journey so far you are in luck because I have written about it.</p>
            <div class="text-center">
                <button type="button" class="btn btn-brand" routerLink="about">Read more</button>
            </div>
        </div>
        <div class="col-md-8 col-xs-12">
            <img class="hello-image" src="assets/images/fullbody.png">
        </div>
    </div>
</div>