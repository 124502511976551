<div class="container mt-5">
    <div class="row mt-5" *ngFor="let service of services; let i = index" id="{{service.fragment}}">
        <div class="service-big col-md-6" *ngIf="i%2==0">
            <a href="#" target="" class="icon-link">
                <fa-icon class="icon" [icon]="service.icon" [styles]="{'color': 'orange'}"></fa-icon>
            </a>
            <h2>{{service.name}}<span class="highlight">.</span></h2>
        </div>
        <div class="col-md-6" *ngIf="i%2==0">
            <div>{{service.longDescription}}</div>
            <ul *ngIf="service.dotPoints">
                <li *ngFor="let dp of service.dotPoints">{{dp}}</li>
            </ul>
            <div>{{service.longDescriptionContinued}}</div>
        </div>
        <div class="col-md-6" *ngIf="i%2!=0">
            <div>{{service.longDescription}}</div>
            <ul *ngIf="service.dotPoints">
                <li *ngFor="let dp of service.dotPoints">{{dp}}</li>
            </ul>
            <div>{{service.longDescriptionContinued}}</div>
        </div>
        <div class="service-big col-md-6" *ngIf="i%2!=0">
            <a href="#" target="" class="icon-link">
                <fa-icon class="icon" [icon]="service.icon" [styles]="{'color': 'orange'}"></fa-icon>
            </a>
            <h2>{{service.name}}<span class="highlight">.</span></h2>
        </div>
    </div>
</div>