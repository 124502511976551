<div class="container">
    <h1 class="text-center">Write me a message<span class="highlight">.</span></h1>
    <p class="subheading">If you have gotten this far reading about me. I'd love to hear more about you! Tell me about your business and if you'd like to work together let me know how I can best help you.</p>
    <form class="contact-me" (ngSubmit)="contactFormSubmit()" #contactForm="ngForm">
        <div class="contact-form-wrapper row">
            <div class="add-margin col-sm-12 col-md-4">
                <div class="row">
                    <input placeholder="Your name (required)" id="name"[(ngModel)]="model.name" name="name" #name="ngModel" required class="form-control" type="text">
                    <div [hidden]="name.valid || name.pristine" class="alert alert-danger">Name is required</div>
                </div>
                <div class="row">
                    <input placeholder="Email (required)" id="email" [(ngModel)]="model.email" name="email" #email="ngModel" required class="form-control" type="text">
                    <div [hidden]="email.valid || email.pristine" class="alert alert-danger">Email is required</div>
                </div>
                <div class="row">
                    <input placeholder="Phone number" id="phone" [(ngModel)]="model.phone" name="phone" #phone="ngModel" class="form-control" type="text">
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-8 add-margin">
                <textarea type="text" placeholder="Your message (required)" id="message" [(ngModel)]="model.message" name="message" #message="ngModel" required class="form-control"></textarea>
                <div [hidden]="message.valid || message.pristine" class="alert alert-danger">Message is required</div>
            </div>
        </div>
        <div class="contact-form-submit-wrapper row">
            <div class="col-sm-12 col-xs-12">
                <div class="text-right">
                    <button class="btn btn-brand" type="submit" [disabled]="!contactForm.form.valid">Send to Ryan <fa-icon [icon]="faArrowRight"></fa-icon></button>
                </div>
            </div>
        </div>
    </form>
</div>