import { Injectable } from '@angular/core';
import { Service } from '../classes';
import { faLightbulb, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faClipboardList, faCode } from '@fortawesome/free-solid-svg-icons';
@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor() { }

  getServices(): Array<Service> {
    // tslint:disable: max-line-length
    const auditing = new Service(
      'audit',
      faClipboardList,
      'Auditing and Code Review',
      'Worried about the progress of a digital project? Want to know the true state of where your team is sitting with regards to industry best practices? Are you building a future-proof digital platform or are you walking into the abyss? Let me help you discover the answers to those questions and more',
      'During a typical engineering team and product audit my goal is to establish the direction, current skill level, culture and business value of your team and suggest strategies that can improve the efficiency and effectiveness of team members.',
      [
        'We start by establishing the direction the business currently is taking and where it is wanting to be in terms of product offerings',
        'From there we begin a deep technical dive into the current software that has been produced and in concrete terms define where it is on track or needs improvement',
        'Following on from that we interview every member of our technical team and establish what their real capabilities (it is surprising how many businesses have deep technical talent wasting away because their skills aren\'t being utilised) are and what they have been wanting to achieve within the current product and we see if they align with the current business goals',
        'After this a report is prepared outlining everything that has been discovered during the analysis phase for the business and offering some key courses of action that the team must undertake to meet the business goals',
        'Based on the outcome of this report businesses typically require a phase of coaching and guidance to the team as a whole in order to assist in the overall alignment shift of the team. I look to increase team productivity through the promotion of knowledge sharing, software engineering best practices and code review patterns.'
      ],
      'This process is tailored specifically to your business and will result in a better product and team while keeping your long term costs down.');
    const consulting = new Service(
      'consult',
      faHandshake,
      'Workshops and Consulting',
      'Need help planning the next big software feature or product at your company? Does your team need guidance in choosing appropriate technologies? Do you need a go to guide for coding standards within your organisation? I can walk you through this.',
      'Do you need some quick help with picking the right technology stack for your next project? Does your team have a knowledge gap that needs to be filled? Would you love some reference material and repository setups to copy, follow or model in order to write great software? My team and I can help deliver training in the form of written, video, webinar or in person training for the following technologies and more',
      [
        'Cost analysis of currently deployed projects and where your hard earned $ can be recouped',
        'Angular 2 to 9+ - We offer a comprehensive reference architecture and 4 hour - 2 day sessions depending on the requirements and size of the team',
        'NestJs - Picking a up a new backend framework can often be difficult. This doesn\'t have to be the case. Let us walk you through your use of NestJs. Reference architectures and training on aspects relevant to your projects can be done in 4 hour - 2 day sessions',
        'TypeORM setups, migrations, seeds and factories -  Ever wondered what an enterprise grade data layer looked like in the TypeScript world? How do you fill a test database with sample data? Let us show you and help you to implement this into your project. 4 - 8 hour sessions available',
        'Monorepo setups - Why you should or shouldn\'t use this on your project. How can this be achieved effectively in a multi-app or microservice pattern? What should or shouldn\'t be shared? Come with questions we will give you answers.',
        'Serverless architectures - How can you leverage Function as a Service in your next project? How do you set up a project for success when being deployed in smaller units? How about data access?',
        'DevOps pipelines - Does your team take a long time to go from development code to deployed? Do you have quality gates set up in your process to ensure reviews happen? Do you have Development, Review, Production servers? How about being able to deploy a single feature branch to test it in isolation? Are you doing this in a secure manner? The scale of this service depends on each project.'
      ],
      'Along with training provided in the above aspects my team offers a Done For You variant of these if you really just need an external team to handle this inside your business.');

    const ideation = new Service(
      'ideate',
      faLightbulb,
      'Ideation, Validation and MVP builds',
      'Do you want to know if the next big app / digital product idea you have has legs? Do you need help locking down a minimum viable feature set for testing your product in the wild? Would you like an experienced team to work on an MVP of your product with a quick turn around in order to test it in the market? We should talk.', 
      'So you have a problem that you want to solve and want to come up with some ideas on how to solve it or you already have some ideas and want to validate them from a product, techinical and market fit standpoint. Generally nailing this step in the product development cycle is required in order to create a successful offering for your customers. So how do we go about this?',
      [
        'First we sit down and identify who your key initial customers are and what (if any) current offerings on the market are servicing their needs',
        'From here we brain storm a number of potential solutions to the problem that differentiate from your competitors offerings. We do this to provide a moat arround your offering so that you do not have to compete on price as you offer a superior product',
        'Following on from the brainstorming sessions we put on our market research hats and look deeply into terms your future customers are searching for online in an attempt to discover how your new ideas will be recieved by the market',
        'After this we approach a few key customers and interview them with our top 5 solutions in mind. During this time we ask them questions about their thoughts of our offering vs the competitors, how much time this would save their business per week/month/year and how much they would be willing to pay to have that time saved. Sometimes it is even possible to land a pre-sale here to assist in validation.',
        'Then it is time to make a decision. "Is this product actually worth building?". There are a number of factors that come into this decision but the call will ultimately come down to your business',
        'If you decide to proceed with the idea and solution in their current or altered form we are available to point you in the right direction in getting a Minimum Loveable Product built or you can hand my team the reigns and we can build it for you'
      ],
      'Different products require different levels of research and validation so this is a bespoke service tailored directly to your business requirements. Along with this MVP builds are normally an expensive and time consuming task for other engineering teams, our team has developed technology to ensure much faster than industry standard turn arounds on getting your product live and in the hands of customers for testing.');

    const development = new Service(
      'dev',
      faCode,
      'Full Service Development',
      'You have the idea. You know that it needs enterprise grade execution. You need it done in a timely and professional manner. You can hire my team to help bring a digital product to life. From ideation to deployment we can be there every step of the way. We can do all web, mobile or infstructure development.',
      'If you need a new product or service developed from the ground up. My team is here to make that happen for you. Your software will be developed using robust modern software development architectures with you core business goals kept at the forefront to ensure a fantastic outcome. Our custom development process can involve as much or as little involvement from your current team as you like.',
      [
        'Custom E-commerce stores that use state of the art SEO and tracking technologies to let you know exactly where your customers are coming from',
        'Bespoke websites especially suited and branded to your business',
        'Fully interactive web applications. Customers coming to your digital business expect to be able to interact with a digital product. We can help you serve them appropriately with well-built mordern user experiences',
        'We also specialise in custom integrations between your apps and websites and ERPs, warehousing solutions, CRMs, email marketing plaforms and many more. If it is possible my team will achieve it'
      ],
      'Building a digital product from scratch is a big undertaking for all involved and prior to commencing we can assist you in a feasibility study to ensure that what you are building is worth your time and money. On completion of any project we are also happy to provide instructions for your own teams to take over if you decide to continue development without our assistance. If this sounds like the direction you would like to take do not hesistate to reach out and start a conversation.');

    return [auditing, ideation, consulting, development];
  }
}
