import { Author } from './author';

export class Article {
    heading: string;
    subheading: string;
    description: string;
    author: Author;
    datePublished: Date;
    readTime: number;
    heroImageUrl: string;
    heroImageAlt: string;
}
