<div class="container">
    <div class="row">
        <div class="col-md-12 col-lg-7">
            <ul class="list-inline">
                <li class="list-inline-item" *ngFor="let socialIcon of socialIcons">
                    <a href="{{socialIcon.link}}" target="_blank">
                        <fa-icon [icon]="socialIcon.icon" [styles]="{'color': '#fff'}"></fa-icon>
                    </a>
                </li>
            </ul>
            <div class="align-middle">
                <h1 class="d-xl-none">I am Ryan</h1>
                <h1 class="d-none d-xl-block">I am Ryan Handby</h1>
                <p>progammer, business builder and educator for freelancers and agencies all over the world</p>
                <p class="smaller-text">I send out occaisional emails with top tips for developers, business builders, makers, entrepreneurship and any notable learnings I come across while working on <a href="https://milestonepay.com">MilestonePay</a>.</p>
                <p class="smaller-text">If you would like to continuing being an awesome human leave me your name and email and I will send you a copy</p>
                <!-- Begin Mailchimp Signup Form -->
                <div id="mc_embed_signup">
                    <form action="https://ryanhandby.us4.list-manage.com/subscribe/post?u=ec9c967a1caad50b6363142a0&amp;id=fffbe1f8b7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                        <div id="mc_embed_signup_scroll">
                            <div class="form-group row">
                                <label for="mce-FNAME" class="col-sm-2 col-form-label col-form-label-sm">First Name</label>
                                <div class="col-sm-10">
                                    <input type="text" value="" name="FNAME" class="form-control form-control-sm" id="mce-FNAME" placeholder="Tony">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mce-LNAME" class="col-sm-2 col-form-label col-form-label-sm">Last Name</label>
                                <div class="col-sm-10">
                                    <input type="text" value="" name="LNAME" class="form-control form-control-sm" id="mce-LNAME" placeholder="Stark">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mce-EMAIL" class="col-sm-2 col-form-label col-form-label-sm">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" value="" name="EMAIL" class="required email form-control form-control-sm" id="mce-EMAIL" placeholder="mr.roboto@starkindustries.io">
                                </div>
                            </div>
                            <div id="mce-responses" class="clear">
                                <div class="response" id="mce-error-response" style="display:none"></div>
                                <div class="response" id="mce-success-response" style="display:none"></div>
                            </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ec9c967a1caad50b6363142a0_fffbe1f8b7" tabindex="-1" value=""></div>
                            <div class="clear">
                            <div class="text-center">
                                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn-brand"></div>
                                <!-- <button type="button" class="btn btn-brand">Subscribe</button> -->
                            </div>
                        </div>
                    </form>
                </div>
                <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="photo-email">
                <img height="607" width="508" src="assets/images/cutout.png" class="attachment-full size-full" alt="Photo of Ryan">
            </div>
        </div>
    </div>
</div>
