export class Menu {
    brand: MenuBrand;
    items: MenuItem[];
    constructor(brand: MenuBrand, items: MenuItem[]){
        this.brand = brand;
        this.items = items;
    }
    get leftItems(): MenuItem[] {
        return this.items.filter(i => i.side === SideEnum.Left);
    }
    get rightItems() {
        return this.items.filter(i => i.side === SideEnum.Right);
    }
}

export enum SideEnum {
    Left,
    Right
}

export class MenuBrand {
    constructor(link: string, cssClasses?: string[]) {
        this.link = link;
        this.cssClasses = cssClasses;
    }
    link: string;
    cssClasses?: string [];
}

export class MenuBrandImage extends MenuBrand {
    src: string;
    alt: string;
}

export class MenuBrandText extends MenuBrand {
    constructor(name: string, link: string, cssClasses?: string[]) {
        super(link, cssClasses);
        this.name = name;
    }
    name: string;
}

export class MenuItem {
    constructor(name: string, side: SideEnum, disabled: boolean, cssClasses?: string[]) {
        this.name = name;
        this.side = side;
        this.disabled = disabled;
        this.cssClasses = cssClasses;
    }
    name: string;
    disabled: boolean;
    side: SideEnum;
    cssClasses?: string[];
}

export class MenuItemActivatable extends MenuItem {
    constructor(name: string, side: SideEnum, active: boolean, disabled: boolean, cssClasses?: string[]) {
        super(name, side, disabled, cssClasses);
        this.active = active;
    }
    active: boolean;
}

export class MenuItemLink extends MenuItemActivatable {
    constructor(name: string, link: string, side: SideEnum, active: boolean, disabled: boolean, cssClasses?: string[]) {
        super(name, side, active, disabled, cssClasses);
        this.link = link;
    }

    link: string;

    get isActive(): boolean {
        return this.active;
    }
}

export class MenuItemDropDown extends MenuItemActivatable {
    constructor(
        name: string, side: SideEnum, active: boolean, disabled: boolean, cssClasses?: string[], children?: MenuItemLink[]) {
        super(name, side, active, disabled, cssClasses);
        this.children = children;
    }
    children: MenuItemLink[];
    get isActive(): boolean {
        return this.active || this.children?.some(c => c.isActive);
    }
}

export class MenuItemDropDownLink extends MenuItemDropDown {
    constructor(
        name: string, link: string, side: SideEnum, active: boolean, disabled: boolean, cssClasses?: string[], children?: MenuItemLink[]) {
        super(name, side, active, disabled, cssClasses, children);
        this.link = link;
    }
    link: string;
}

export class MenuItemInput extends MenuItem {
    placeholder: string;
}

export class MenuItemButton extends MenuItem {
    text: string;
}
