import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/classes/blog';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  article: Article;
  constructor() {
    this.article = new Article();
    this.getArticleContents();
  }

  ngOnInit(): void {
  }

  getArticleContents(){
    this.article.heading = 'How to respond to COVID-19';
    this.article.subheading = 'HANDLING CORONAVIRUS';
    this.article.description = 'And prepare for the next epidemic, too.';
  }
}
