<div class="container">
    <h1 class="text-center">Things I do<span class="highlight">.</span></h1>
    <p class="subheading">I love helping entrepreneurs, startups and larger businesses create better products through education, leadership and software architecture engineering.</p>
    <div class="services-block-row row">
        <div class="service col-sm-6 col-lg-3" *ngFor="let service of services">
            <a href="#" target="" class="icon-link">
                <fa-icon class="icon" [icon]="service.icon" [styles]="{'color': 'orange'}"></fa-icon>
            </a>
            <h2>{{service.name}}<span class="highlight">.</span></h2>
            <p class="service-description">
                {{service.shortDescription}}
            </p>
            <div class="text-center mt-3">
                <button type="button" class="btn btn-brand" routerLink="{{needRouterLink ? 'services' : '.'}}" fragment="{{service.fragment}}">Find out More</button>
            </div>
        </div>
    </div>
</div>