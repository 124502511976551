import { Component, OnInit } from '@angular/core';
import { Menu, MenuBrandText, MenuItemLink, SideEnum, MenuItem, MenuItemDropDownLink, MenuItemDropDown } from 'src/app/classes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menu: Menu;
  isCollapsed = true;
  constructor() {
    this.constructMenu();
  }

  ngOnInit(): void {
  }

  constructMenu() {
    const menuBrand: MenuBrandText = new MenuBrandText('Ryan Handby', '/');

    const home: MenuItemLink = new MenuItemLink('Home', '/', SideEnum.Right, true, false);
    const services: MenuItemLink = new MenuItemLink('Services', '/services', SideEnum.Right, true, false);
    // const blogTopics: MenuItemLink[] = [
    //   new MenuItemLink('Programming', '/blog/programming', SideEnum.Right, true, false),
    //   new MenuItemLink('Entrepreurship', '/blog/entrepreneurship', SideEnum.Right, true, false)
    // ];
    // const blog: MenuItemDropDown = new MenuItemDropDown('Blogs', SideEnum.Right, true, false, null, blogTopics);
    const blog: MenuItemLink = new MenuItemLink('Blog', 'https://medium.com/@ryanhandby', SideEnum.Right, true, false)
    const podcast: MenuItemLink = new MenuItemLink('Podcast', 'https://anchor.fm/brief-and-deep', SideEnum.Right, true, false)
    const about: MenuItemLink = new MenuItemLink('About', '/about', SideEnum.Right, true, false);

    this.menu = new Menu(menuBrand, [home, services, blog, podcast, about]);

  }

  isActualUrl(url: any){
    return url.includes('https://');
  }
}
