import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, AfterViewInit {
  fragment: string = null;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewInit() {
    this.jumpToFragment();
  }

  jumpToFragment(){
    if(this.fragment) {
      const id = document.querySelector('#' + this.fragment);
      if(id){
        id.scrollIntoView();
      }
    }
  }

}
