<header>
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" routerLink="{{menu?.brand.link}}">{{menu?.brand.name}}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#topNav" aria-controls="topNav" aria-label="Toggle navigation" [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed">
                <span class="navbar-toggler-icon"></span>
            </button>
        
            <div class="collapse navbar-collapse" id="topNav" [ngClass]="{'show': !isCollapsed}">
                <ul class="navbar-nav mr-auto">
                    <ng-container *ngFor="let item of menu.leftItems">
                        <li class="nav-item" *ngIf="(!item.children && isActualUrl(item.link))">
                            <a class="nav-link" href="{{item.link}}">{{item.name}}</a>
                        </li>
                        <li class="nav-item" routerLink="{{item.link}}" routerLinkActive="active" *ngIf="(!item.children && !isActualUrl(item.link))">
                            <a class="nav-link">{{item.name}}</a>
                        </li>
                        <li class="nav-item dropdown" routerLink="{{item.link}}" routerLinkActive="active" dropdown *ngIf="item.children">
                            <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown" dropdownToggle>
                              {{item.name}}
                            </a>
                            <div class="dropdown-menu" *dropdownMenu>
                              <a class="dropdown-item" href="#" *ngFor="let grandchild of item.children">{{grandchild.name}}</a>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <ul class="navbar-nav ml-auto">
                    <ng-container *ngFor="let item of menu.rightItems">
                        <li class="nav-item" *ngIf="(!item.children && isActualUrl(item.link))">
                            <a class="nav-link nav-link-real" href="{{item.link}}">{{item.name}}</a>
                        </li>
                        <li class="nav-item" routerLink="{{item.link}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="(!item.children && !isActualUrl(item.link))">
                            <a class="nav-link">{{item.name}}</a>
                        </li>
                        <li class="nav-item dropdown" routerLinkActive="active" dropdown *ngIf="item.children">
                            <a class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown" dropdownToggle>
                              {{item.name}}
                            </a>
                            <div class="dropdown-menu" *dropdownMenu>
                              <a class="dropdown-item" routerLink="{{grandchild.link}}" routerLinkActive="active" *ngFor="let grandchild of item.children">{{grandchild.name}}</a>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </nav>
    </div>
</header>