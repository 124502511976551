import { Component, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-contact-me-block',
  templateUrl: './contact-me-block.component.html',
  styleUrls: ['./contact-me-block.component.scss']
})
export class ContactMeBlockComponent implements OnInit {
  faArrowRight = faArrowRight;
  model: any;
  constructor() {
    this.model = {};
    this.model.name = "";
    this.model.email = "";
    this.model.phone = "";
    this.model.message = "";
  }

  ngOnInit(): void {
  }

  async contactFormSubmit(){
    const res = await fetch('/api/post-send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.model)
    });
    const text = await res.text();
    console.log(text);
  }

}
